import styled from "styled-components"

import { GooglePlacesAutoComplete } from "src/components/Homes/GooglePlacesAutoComplete"
import { extractAddressData } from "src/data/homeAddress/logic/homeAddressDataUtils"
import { THomeAddress } from "src/data/homes/types/homeTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Heading2Mixin } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function HomeAddressAutoCompleteStep({
  address,
  setAddress,
  onAutoComplete,
}: {
  address: string
  setAddress: (addres: string) => void
  onAutoComplete: (address: THomeAddress) => void
}) {
  const { t, langKeys } = useTranslate()
  // https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes
  function getAddress({
    address: currentAddress,
    addressComponents,
  }: {
    address: string
    addressComponents: google.maps.GeocoderAddressComponent[] | null
  }) {
    if (!addressComponents) {
      return
    }
    setAddress(currentAddress)
    onAutoComplete(extractAddressData(addressComponents))
  }

  return (
    <div>
      <Title>{t(langKeys.home_address_wizard_auto_complete_title)}</Title>

      <GooglePlacesAutoComplete
        inputValue={address}
        setInputValue={setAddress}
        onLocationSelected={getAddress}
        label={t(langKeys.home_address_wizard_auto_complete_label)}
      />
    </div>
  )
}

const Title = styled.div`
  margin-bottom: ${spacing.XL2};
  ${Heading2Mixin};
`
