import { useEffect, useState } from "react"

import { HomeAddressAutoCompleteStep } from "src/components/Homes/HomeAddress/HomeAddressAutoCompleteStep"
import {
  HOME_ADDRESS_FORM_ID,
  HomeAddressStep,
} from "src/components/Homes/HomeAddress/HomeAddressStep"
import {
  HOME_EMERGENCY_CONTACT_FORM_ID,
  HomeEmergencyContactStep,
} from "src/components/Homes/HomeAddress/HomeEmergencyContactStep"
import { HomeMapStep } from "src/components/Homes/HomeAddress/HomeMapStep"
import { usePostTrackGuardAssistEvents } from "src/data/analytics/queries/guardAssistAnalyticsQueries"
import {
  useFetchProviderHomes,
  useFetchResponseServiceHomes,
  useFetchResponseServiceProvider,
  useFetchResponseServiceToggleEstimate,
  usePatchResponseService,
} from "src/data/homes/queries/responseServiceQueries"
import {
  IEmergencyContact,
  ILocation,
  THome,
  THomeAddress,
  TMaybeHome,
} from "src/data/homes/types/homeTypes"
import {
  IResponseServiceProviderResponse,
  ResponseServiceToggleAction,
} from "src/data/homes/types/responseServiceTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { usePatchHome } from "src/data/organizations/queries/homeQueries"
import { ResponseServiceConfigurationError } from "src/data/responseService/types/responseServiceTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { LoadingScreen } from "src/ui/LoadingScreen/LoadingScreen"
import { FullscreenWizard } from "src/ui/Wizard/FullscreenWizard"
import { ButtonDirection, IWizardStep } from "src/ui/Wizard/wizardTypes"
import { ErrorService } from "src/utils/ErrorService"
import { getTimeZoneFromLoc } from "src/utils/l10n"

import { EnableStep } from "./EnableStep"
import { FinishedStep } from "./FinishedStep"
import { HOME_SELECT_FORM_ID, HomeSelectStep } from "./HomeSelectStep"

export function ResponseServiceWizard({
  provider,
}: {
  provider: IResponseServiceProviderResponse["id"]
}) {
  const { navigate } = useRouter()
  const fetchResponseServiceProvider = useFetchResponseServiceProvider({
    providerId: provider || "",
    options: {
      enabled: !!provider,
    },
  })

  const { t, langKeys } = useTranslate()
  const [currentStep, setCurrentStep] = useState(0)
  const [selectedHome, setSelectedHome] = useState<TMaybeHome>(null)
  const [homeAddress, setHomeAddress] = useState<THomeAddress>()
  const [autoCompleteAddress, setAutoCompleteAddress] = useState<THomeAddress>()
  const [autoCompleteAddressString, setAutoCompleteAddressString] =
    useState<string>("")
  const [homeLocation, setHomeLocation] = useState<ILocation>()
  const [hasEnabled, setHasEnabled] = useState(false)
  const [missingPhoneNumberError, setMissingPhoneNumberError] = useState(false)
  const { org } = useOrganization()
  const user = useGetUser()
  const patchHome = usePatchHome()
  const patchResponseService = usePatchResponseService()
  const fetchHomes = useFetchResponseServiceHomes({ filters: { limit: 1 } })
  const firstTimeEnabledMRP =
    hasEnabled && (fetchHomes.data?.paging.total_count ?? 0) === 1
  const hasPreviouslyEnabledMRP =
    (fetchHomes.data?.paging.total_count ?? 0) > 0 && !firstTimeEnabledMRP

  const selectedHomeId = selectedHome?.home_id
  // Ensure new home address is still valid for provider if user changes address
  const { orgId } = useOrganization()
  const fetchProviderHomes = useFetchProviderHomes({
    orgId,
    homeIds: selectedHomeId ? [selectedHomeId] : [],
    filter: {
      address: JSON.stringify(selectedHome?.address),
    },
    options: {
      enabled: !!selectedHomeId,
    },
  })
  const responseServiceAvailable = fetchProviderHomes.data?.some(
    (p) =>
      p.home_id === selectedHomeId && p.providers.some((p) => p.id === provider)
  )

  const fetchEstimate = useFetchResponseServiceToggleEstimate({
    homeId: selectedHomeId ?? "",
    toggleAction: ResponseServiceToggleAction.REGISTER,
    options: {
      onError: (error) => {
        ErrorService.captureException(error)
      },
      enabled: !!selectedHomeId,
    },
    provider,
  })

  // Analytics hooks
  const postTrackSignupInit = usePostTrackGuardAssistEvents(
    "Guard Assist Sign Up Initiated"
  )
  const postTrackAdditionalHomeInit = usePostTrackGuardAssistEvents(
    "Guard Assist Add Addtional Home Initiated"
  )
  const postTrackHomeSelectSubmit = usePostTrackGuardAssistEvents(
    "Guard Assist Home Select Submitted"
  )
  const postTrackHomeAddressSubmit = usePostTrackGuardAssistEvents(
    "Guard Assist Home Address Submitted"
  )
  const postTrackAddressMapSubmit = usePostTrackGuardAssistEvents(
    "Guard Assist Address Map Submitted"
  )
  const postTrackEmergencyContactSubmit = usePostTrackGuardAssistEvents(
    "Guard Assist Emergency Contact Submitted"
  )
  const postTrackActivateSubmit = usePostTrackGuardAssistEvents(
    "Guard Assist Activate Submitted"
  )

  useEffect(() => {
    if (fetchHomes.isLoading || !fetchHomes.data) {
      return
    }

    if (hasPreviouslyEnabledMRP) {
      postTrackAdditionalHomeInit.mutate({
        userId: user.user_id,
        orgId: org.id,
        providerId: provider,
      })
    } else {
      postTrackSignupInit.mutate({
        userId: user.user_id,
        orgId: org.id,
        providerId: provider,
      })
    }
    // No need to check for the mutation hooks, since they should not change.
    // Also we don't want it to run this effect for changes on other state
    // variables for the mutations, example isLoading
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasPreviouslyEnabledMRP,
    fetchHomes.isLoading,
    org.id,
    user.user_id,
    fetchHomes.data,
  ])

  function handleSubmit(selectedHome: TMaybeHome) {
    if (!selectedHome) return

    setHomeAddress(selectedHome.address)
    setHomeLocation(selectedHome.location)
    setCurrentStep((currStep) => currStep + 1)
    //
    // use mutate async to not crash the component if the mutation fails
    postTrackHomeSelectSubmit.mutate({
      userId: user.user_id,
      homeId: selectedHome.home_id,
      orgId: org.id,
    })
  }

  function handleHomeAddressStep(address: THomeAddress, location: ILocation) {
    // Spread address here since otherwise it might overwrite things not handled by the form, like notes
    setHomeAddress((prev) => ({ ...prev, ...address }))
    setHomeLocation(location)
    setCurrentStep((currStep) => currStep + 1)

    postTrackHomeAddressSubmit.mutate({
      userId: user.user_id,
      homeId: selectedHome?.home_id,
      orgId: org.id,
    })
  }

  function handleHomeEmergancyContactStep({
    notes,
    ...emergencyContact
  }: Partial<IEmergencyContact> & Pick<THomeAddress, "notes">) {
    if (
      selectedHome &&
      emergencyContact.name &&
      emergencyContact.phone_number
    ) {
      const homeData: Partial<THome> = {
        address: homeAddress ? { ...homeAddress, notes } : undefined,
        location: homeLocation,
        timezone: homeLocation
          ? getTimeZoneFromLoc(homeLocation?.latitude, homeLocation?.longitude)
          : undefined,
        emergency_contact: {
          name: emergencyContact.name,
          phone_number: emergencyContact.phone_number,
        },
      }

      postTrackEmergencyContactSubmit.mutate({
        userId: user.user_id,
        orgId: org.id,
        homeId: selectedHome.home_id,
      })

      return patchHome.mutate(
        {
          orgId: org.id,
          homeId: selectedHome.home_id,
          data: homeData,
        },
        {
          onSuccess: (data) => {
            setSelectedHome(data)
            setCurrentStep((currStep) => currStep + 1)
          },
        }
      )
    }
  }

  function handleEnableStep() {
    if (!selectedHome) {
      return
    }
    patchResponseService.mutate(
      {
        homeId: selectedHome.home_id,
        orgId: org.id,
        body: {
          active: true,
          subscription_status: "active",
          provider,
        },
      },
      {
        onSuccess: () => {
          setCurrentStep((currStep) => currStep + 1)
          setHasEnabled(true)
        },
        onError: (err) => {
          err.response?.data.error_key ===
            ResponseServiceConfigurationError.MISSING_PHONE_NUMBER_ERROR &&
            setMissingPhoneNumberError(true)
        },
      }
    )

    postTrackActivateSubmit.mutate({
      userId: user.user_id,
      orgId: org.id,
      homeId: selectedHome.home_id,
    })
  }

  const stepper: IWizardStep[] = [
    {
      component: (
        <HomeSelectStep
          providerInformation={fetchResponseServiceProvider.data}
          selectedHome={selectedHome}
          onSelected={setSelectedHome}
          onSubmit={() => handleSubmit(selectedHome)}
        />
      ),
      hideBackButton: true,
      nextButtonProps: {
        form: HOME_SELECT_FORM_ID,
        disabled: !selectedHome,
      },
    },
    {
      component: (
        <HomeAddressAutoCompleteStep
          address={autoCompleteAddressString}
          setAddress={setAutoCompleteAddressString}
          onAutoComplete={setAutoCompleteAddress}
        />
      ),
      nextButtonLabel: t(langKeys.continue),
      onNext: () => {
        setHomeAddress(autoCompleteAddress)
        setCurrentStep((currStep) => currStep + 1)
      },
      skip: !!homeAddress && !autoCompleteAddress,
    },
    {
      component: (
        <HomeAddressStep
          onSuccess={handleHomeAddressStep}
          address={homeAddress}
          location={homeLocation}
          setupForGuardService={true}
        />
      ),
      nextButtonProps: {
        form: HOME_ADDRESS_FORM_ID,
      },
      nextButtonLabel: t(langKeys.continue),
    },
    {
      component: (
        <HomeMapStep
          onChangeLocation={setHomeLocation}
          location={homeLocation}
        />
      ),
      onNext: () => {
        setCurrentStep((currStep) => currStep + 1)

        postTrackAddressMapSubmit.mutate({
          userId: user.user_id,
          orgId: org.id,
          homeId: selectedHome?.home_id,
        })
      },
      nextButtonLabel: t(langKeys.update_and_continue),
    },
    {
      component: (
        <HomeEmergencyContactStep
          onSuccess={handleHomeEmergancyContactStep}
          setupForGuardService={true}
          emergencyContact={selectedHome?.emergency_contact}
          notes={selectedHome?.address?.notes}
        />
      ),
      nextButtonProps: {
        form: HOME_EMERGENCY_CONTACT_FORM_ID,
        loading: patchHome.isLoading,
      },
      nextButtonLabel: t(langKeys.continue),
    },
    {
      component: (
        <EnableStep
          error={patchResponseService.isError || fetchProviderHomes.isError}
          missingPhoneNumberError={missingPhoneNumberError}
          responseServiceAvailable={responseServiceAvailable}
          estimateData={fetchEstimate.data}
          estimateError={fetchEstimate.error}
        />
      ),
      nextButtonLabel: t(langKeys.activate),
      onNext: handleEnableStep,
      border: false,
      direction: ButtonDirection.COLUMN,
      nextButtonProps: {
        loading:
          patchResponseService.isLoading ||
          fetchProviderHomes.isLoading ||
          fetchEstimate.isInitialLoading,
        disabled:
          missingPhoneNumberError ||
          patchResponseService.isError ||
          fetchProviderHomes.isError ||
          !fetchEstimate.data ||
          !responseServiceAvailable,
      },
      onBack: () => {
        setCurrentStep((currStep) => currStep - 1)
        setMissingPhoneNumberError(false)
      },
    },
    {
      component: (
        <FinishedStep hasPreviouslyEnabledMRP={hasPreviouslyEnabledMRP} />
      ),
      nextButtonLabel: t(langKeys.ok),
      hideBackButton: true,
      onNext: () => navigate(Routes.ResponseService.location()),
    },
  ]

  if (fetchHomes.isLoading || fetchResponseServiceProvider.isInitialLoading) {
    return <LoadingScreen debugInfo={"Loading response service wizard"} />
  }

  return (
    <FullscreenWizard
      title={t(langKeys.response_service_add_button)}
      steps={stepper}
      currentStep={currentStep}
      onClose={() => navigate(Routes.ResponseService.location())}
      onNext={() => setCurrentStep((currStep) => currStep + 1)}
      onBack={() => setCurrentStep((currStep) => currStep - 1)}
    />
  )
}
