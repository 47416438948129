import { useScript } from "usehooks-ts"

export function CalendlyWidget() {
  useScript("https://assets.calendly.com/assets/external/widget.js", {
    removeOnUnmount: true,
  })

  return (
    <div
      className="calendly-inline-widget"
      data-url="https://calendly.com/mljungblad/onboarding?hide_event_type_details=1&hide_gdpr_banner=1"
      style={{
        minWidth: "320px",
        height: "500px",
      }}
    />
  )
}
