import { LazyLoadImage } from "react-lazy-load-image-component"
import styled from "styled-components"

import { CalendlyWidget } from "src/components/ResponseService/CalendlyWidget"
import Marcus from "src/components/ResponseService/images/marcus-book-a-call.jpg"
import { useTranslate } from "src/i18n/useTranslate"
import { divider } from "src/ui/colors"
import InformationIcon from "src/ui/icons/information-notification.svg"
import ResponseServiceIcon from "src/ui/icons/response-service.svg"
import BadgeOneIcon from "src/ui/icons/responseService/badge-number-1.svg"
import BadgeTwoIcon from "src/ui/icons/responseService/badge-number-2.svg"
import BadgeThreeIcon from "src/ui/icons/responseService/badge-number-3.svg"
import NoNoiseIcon from "src/ui/icons/sound-crossed.svg"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function FinishedStep({
  hasPreviouslyEnabledMRP,
}: {
  hasPreviouslyEnabledMRP: boolean
}) {
  const { t, langKeys } = useTranslate()

  return (
    <div>
      <MText variant="heading1" marginBottom={spacing.XL}>
        {t(langKeys.response_service_wizard_complete_title)}
      </MText>
      {hasPreviouslyEnabledMRP ? (
        <>
          <MText variant="heading2" marginBottom={spacing.XL4}>
            {t(langKeys.response_service_wizard_complete_description)}
          </MText>
          <Grid>
            <SummaryGroup
              badgeIcon={<BadgeOneIcon />}
              descriptiveIcon={<InformationIcon />}
              sectionTitle={t(
                langKeys.response_service_wizard_complete_info_title
              )}
              sectionDescription={t(
                langKeys.response_service_wizard_complete_info_description
              )}
            />
            <SummaryGroup
              badgeIcon={<BadgeTwoIcon />}
              descriptiveIcon={
                <ResponseServiceIcon width="64px" height="64px" />
              }
              sectionTitle={t(
                langKeys.response_service_wizard_complete_responder_title
              )}
              sectionDescription={t(
                langKeys.response_service_wizard_complete_responder_description
              )}
            />
            <SummaryGroup
              badgeIcon={<BadgeThreeIcon />}
              descriptiveIcon={<NoNoiseIcon />}
              sectionTitle={t(
                langKeys.response_service_wizard_complete_noise_title
              )}
              sectionDescription={t(
                langKeys.response_service_wizard_complete_noise_description
              )}
            />
          </Grid>
        </>
      ) : (
        <div>
          <CalendlyDescription>
            <MText variant="body">
              30-minute onboarding session with Marcus from Minut to go through
              your setup with Minut, introduction to how Minut Response Partners
              work, and set you up for a complete hands-off noise issue
              resolution process
            </MText>
            <BookaCallCrop>
              <BookACallImage src={Marcus} alt="marcus" effect="opacity" />
            </BookaCallCrop>
          </CalendlyDescription>
          <CalendlyWidget />
        </div>
      )}
    </div>
  )
}

function SummaryGroup({
  badgeIcon,
  descriptiveIcon,
  sectionTitle,
  sectionDescription,
}: {
  badgeIcon: React.ReactNode
  descriptiveIcon: React.ReactNode
  sectionTitle: React.ReactNode
  sectionDescription: React.ReactNode
}) {
  return (
    <SummaryBox>
      <div>
        <div>{badgeIcon}</div>
        <DescriptiveIcon>{descriptiveIcon}</DescriptiveIcon>
      </div>
      <div>
        <MText variant="heading3" marginBottom={spacing.L}>
          {sectionTitle}
        </MText>
        <div>{sectionDescription}</div>
      </div>
    </SummaryBox>
  )
}

const Grid = styled.div`
  display: grid;
  gap: ${spacing.XL};
`

const SummaryBox = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  gap: ${spacing.L};
  padding-bottom: ${spacing.L};
  border-bottom: 1px solid ${divider};
  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }

  @media (min-width: 500px) {
    grid-template-columns: 75px 1fr;
    gap: ${spacing.XL2};
    padding-bottom: ${spacing.XL};
  }
`

const DescriptiveIcon = styled.div`
  padding-left: 15px;
`

const CalendlyDescription = styled.div`
  display: flex;
  gap: ${spacing.M};
  align-items: center;
  margin-bottom: ${spacing.L};
`

const BookaCallCrop = styled.div`
  border-radius: 50%;
  min-height: 100px;
  max-width: 100px;
  max-height: 100px;
  min-width: 100px;
  overflow: hidden;
`

const BookACallImage = styled(LazyLoadImage)`
  position: relative;
  top: -15px;
  left: -30px;
  height: 150px;
  width: 150px;
  object-fit: cover;
`
